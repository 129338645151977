const hostname = 'citysprings\\.com'; // Define once to reuse across functions
const pattern = new RegExp(`^(internal:|(https?://)?(www\\.)?${hostname})/`, 'i');

// Convert absolute URLs to relative URLs if they match the hostname or internal prefix
const convertAbsoluteToRelative = (link) => {
  if (link && typeof link === 'string') {
    return link.replace(pattern, '/');
  }
  return link;
};

// Checks if a URL matches internal or specific hostname patterns
const checkForInternalTicketURL = (url) => {
  return typeof url === 'string' && pattern.test(url);
};

export { convertAbsoluteToRelative, checkForInternalTicketURL };
